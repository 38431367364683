import {
  THW,
  LB,
  RB,
  RBW,
  RBM,
  EC,
  E,
  B,
  EX,
  BS,
  R,
  RS,
  MB,
  BC,
  TCTP,
  CT,
  UAI,
  BETCOUNTER,
} from 'CORE__UI/apps/AuthorizationHeader/core__authHeader';
import styled from 'styled-components';
import { BRAND, FONT } from 'UI/globals/colours';
import {
  BREAKPOINT_L,
  BREAKPOINT_M,
  BREAKPOINT_S,
  BREAKPOINT_XS,
} from 'UI/globals/breakpoints';

const LB_EX = styled(LB)`
  color: ${FONT.white.label};
  font-weight: 500;
  line-height: 20px;
  &:hover {
    border: 0;
    color: ${({ theme, showSecondaryColor }) =>
      theme.casino || showSecondaryColor ? BRAND.secondaryD : BRAND.secondaryD};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: 64px;
  }
  @media (max-width: ${BREAKPOINT_M}) {
    width: 80px;
  }

  @media (max-width: ${BREAKPOINT_XS}) {
    margin-right: 0px;
    font-size: 12px;
    line-height: 10px;
    padding: 0px;
  }
  ${spanMargin =>
    spanMargin &&
    `span {
    margin: auto;
  };`}
  @media (min-width: ${BREAKPOINT_L}) {
    width: max-content;
  }
`;

const RB_EX = styled(RB)`
  color: ${FONT.white.label};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 99px;
  :hover {
    background-color: ${({ theme, showSecondaryColor }) =>
      theme.casino || showSecondaryColor ? BRAND.secondaryD : BRAND.secondaryD};
  }
  background-color: ${({ theme, showSecondaryColor }) =>
    theme.casino || showSecondaryColor ? BRAND.secondary : BRAND.secondary};
`;

const RBM_EX = styled(RBM)`
  @media (max-width: ${BREAKPOINT_S}) {
    min-width: 64px;
    text-align: center;
  }

  @media (max-width: ${BREAKPOINT_XS}) {
    font-size: 12px;
  }
`;

const RBW_EX = styled(RBW)`
  @media (max-width: ${BREAKPOINT_XS}) {
    padding: 0px;
  }
`;

const THW_QN = styled(THW)`
  gap: 16px;
  @media (max-width: ${BREAKPOINT_XS}) {
    gap: 0px;
  }
`;

const CT_EX = styled(CT)`
  @media (max-width: ${BREAKPOINT_XS}) {
    font-size: 12px;
  }
`;

const BETCOUNTER_EX = styled(BETCOUNTER)`
  color: ${FONT.light};
  background: ${BRAND.secondary};
`;

export {
  THW_QN as THW,
  LB_EX as LB,
  RB_EX as RB,
  RBW_EX as RBW,
  RBM_EX as RBM,
  EC,
  E,
  B,
  EX,
  BS,
  R,
  RS,
  MB,
  BC,
  TCTP,
  CT_EX as CT,
  UAI,
  BETCOUNTER_EX as BETCOUNTER,
};
