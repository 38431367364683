import {
  H,
  D,
  S,
  C,
  E,
  MB,
  MStyles,
  ModalButtonClose,
  EYE,
  L,
  J,
  Text,
  Image,
  CI,
  Link,
} from 'CORE__UI/apps/LoginApp/core__loginModal';
import styled from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';

const EYE_EX = styled(EYE)`
  width: 22px;
  color: ${GREYS.silver};
  top: 168px;
  right: 36px;
`;
const L_EX = styled(L)`
  button {
    font-weight: 700;
    &:hover,
    &:active,
    &:focus {
      color: ${GREYS.black};
      outline: none;
    }
  }
`;
const J_EX = styled(J)`
  button {
    font-weight: 400;
  }
  ${({ flex }) =>
    flex &&
    `display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 24px 0;`};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  ${({ noBottomMargin }) => noBottomMargin && `margin-bottom: 0;`};
  a {
    text-decoration: underline;
  }
  ${({ reg }) =>
    reg &&
    `
      text-decoration: none;
      padding: 0 24px;
      button {
        font-weight: bold;
      }
    `}
`;
const C_Text = styled(C)`
  padding-bottom: 16px;
`;
const ModalButtonClose_EX = styled(ModalButtonClose)`
  border-color: ${GREYS.doveD};
  color: ${FONT.dark};
  :hover {
    background-color: ${BRAND.secondary};
    color: ${FONT.light};
  }
`;
const MB_EX = styled(MB)`
  margin-top: 8px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${BRAND.primaryD};
    border-color: ${BRAND.primaryD};
  }
`;
const H_EX = styled(H)`
  color: ${FONT.dark};
  ${({ reg }) =>
    reg &&
    `
      padding: 0 24px;
    `}
`;

const Image_EX = styled(Image)`
  color: ${FONT.dark};
  ${({ reg }) =>
    reg &&
    `
      cursor: pointer;
    `}
`;

const CI_EX = styled(CI)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-top: ${marginTop => marginTop};
`;
export {
  H_EX as H,
  D,
  S,
  C_Text as C,
  E,
  MB_EX as MB,
  MStyles,
  ModalButtonClose_EX as ModalButtonClose,
  EYE_EX as EYE,
  L_EX as L,
  J_EX as J,
  Image_EX as Image,
  CI_EX as CI,
  Text,
  Link,
};
