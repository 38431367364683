import {
  SC,
  AH,
  AV,
  A,
} from 'CORE__UI/apps/AccountApp/AccountHeader/core__accountHeader';
import styled, { css } from 'styled-components';
import { BRAND, FONT } from 'UI/globals/colours';

const AV_EX = styled(AV)`
  font-weight: bold;
  border-bottom: 2px solid transparent;

  &:hover {
    color: ${FONT.black.standard};
    font-weight: bold;
    border-bottom: 2px solid ${BRAND.secondary};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${FONT.black.standard};
      border-color: ${BRAND.secondary};
      font-weight: bold;
    `}
`;

export { SC, AH, AV_EX as AV, A };
